<!--办会员卡-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="姓名/手机号/会员/昵称" v-model="select.search" @change="curr=1;getList();" size="small" style="width:350px;" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList();"></el-button>
                </el-input>
                <el-button type="primary" block size="small" @click="edit()">办会员卡</el-button>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="头像" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.headimgurl" :src ="scope.row.headimgurl" :preview-src-list="[scope.row.headimgurl]" class="tableImage"></el-image>
                        <el-avatar v-else shape="square" :size="45" :src="require('../../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="realname" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" width="100" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="card" width="180" label="会员号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="card" label="办卡方式" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.method == 2 ? '充值办卡' : '直接开通'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userLevel.name" label="开通会员" show-overflow-tooltip></el-table-column>
                <el-table-column prop="userLevel.content" label="充值办会员" show-overflow-tooltip></el-table-column>
                <el-table-column label="会员卡状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.is_examine == 1 ? scope.row.status : '暂未开通'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :class="[scope.row.is_examine == 1 ? 'successColor' : scope.row.is_examine == 2 ? 'primaryColor' : scope.row.is_examine == 3 ? 'errorColor' : '' ]">{{scope.row.is_examine == 1 ? '审核通过' : scope.row.is_examine == 2 ? '待审核' :   '审核拒绝'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="refuse_remarks" label="拒绝原因" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" :disabled="scope.row.is_examine == 1 ? true : false" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" :disabled="scope.row.is_examine == 1 ? true : false" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <div>
            <el-dialog :title="title" custom-class="way" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="600px">
                <el-form ref="formAuth" :rules="formRules" :model="formData" label-width="100px"  size="small" class="alertForm">
                    <el-form-item label="会员姓名" prop="realname" style="width:100%">
                        <el-input v-model="formData.realname" placeholder="请输入姓名" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="会员手机号" prop="mobile" style="width:100%">
                        <el-input v-model="formData.mobile" placeholder="请输入手机号" @input="changeMobile()" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="办卡方式" prop="method" style="width:100%">
                        <el-radio-group v-model="formData.method">
                            <el-radio label="1">开通会员</el-radio>
                            <el-radio label="2">充值办会员</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="formData.method == 1" label="开通会员" :prop="formData.method == 1 ? 'level_id' : ''" style="width:100%">
                        <el-select v-model="formData.level_id" placeholder="开通会员" style="width:100%" clearable>
                            <el-option v-for="item in showData" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="formData.method == 2" label="充值办会员" :prop="formData.method == 2 ? 'level_id' : ''" style="width:100%">
                        <el-select v-model="formData.level_id" placeholder="充值办会员" style="width:100%" clearable>
                            <el-option v-for="item in showData" :label="item.content" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注" prop="remarks" style="width:100%">
                        <el-input type="textarea" :rows="3" resize="none" placeholder="请输入办卡原因" v-model="formData.remarks"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog">取 消</el-button>
                    <el-button size="small" type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        
    </div>
</template>
<script>
    import {createMemberListApi,createMemberShowDataApi,createMemberEditApi,createMemberDeletetApi,isExistMemberApi}  from '@/api/member.js';
    export default {
        data() {
            return {
                select:{},
                showData:{},
                formRules:{
                    realname: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'change'
                    }],
                    mobile: [{
                        required: true,
                        pattern: /^1[0-9][0-9]\d{8}$/,
                        message: '请输入手机号',
                        trigger: 'change'
                    }],
                    level_id: [{
                        required: true,
                        message: '请选择',
                        trigger: 'change'
                    }],
                    method:[{
                        required: true,
                        message: '请选择办卡方式',
                        trigger: 'change'
                    }],
                    
                },
                formData:{}, //详情数据
                selectData:[],//下拉选择列表
                list:[], //会员列表

                title:'新办会员卡',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogFormVisible:false, //弹出框状态
                ispwd:false, //编辑状态下密码是否可填状态
                
            }
        },
        mounted() {
            this.getList();
            this.addShow();
        },
        methods: {
            //办卡列表
            getList: function(){
                createMemberListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
            changeMobile: function(){
                if(this.formData.mobile && this.formData.mobile.length == 11){
                    isExistMemberApi({
                        mobile:this.formData.mobile
                    }).then(response=>{
                        if(response.data.mobile){
                            response.data.method = JSON.stringify(response.data.method)
                            response.data.level_id = response.data.level_id == 0 ? '' : response.data.level_id;
                            this.formData = response.data;
                        }
                    })
                }  
            },

            addShow:function(){
                createMemberShowDataApi().then(response=>{
                    this.showData = response;
                })
            },

            //账号信息更改
            edit: function(param = {}){
                this.dialogFormVisible = true;
                if(typeof param.id == 'undefined'){
                    this.title = '新办会员卡';
                    this.formData = {};
                    this.ispwd = true;
                }else{
                    this.ispwd = false;
                    this.title = '编辑会员卡';
                    this.formData = JSON.parse(JSON.stringify(param));
                    this.formData.method = JSON.stringify(this.formData.method)
                }
            },

            //删除账号
            deletes: function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    createMemberDeletetApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '成功删除!'
                        });
                    })
                }).catch(()=>{})
            },
            //保存
            submit: function(){
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        createMemberEditApi({
                            level_id:this.formData.level_id,
                            name:this.formData.realname,
                            mobile:this.formData.mobile,
                            user_id:this.formData.user_id,
                            method:this.formData.method,
                            remarks:this.formData.remarks
                        }).then(()=>{
                            this.closeDialog();
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '保存成功!'
                            });
                        })
                    }
                })
            },
            closeDialog:function(){
                this.$refs['formAuth'].resetFields();
                this.dialogFormVisible = false;
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>